.header,
.main,
.footer,
.section {
	width: 100%;
	height: auto;
	position: relative;
	float: left;
}

.header {
	position: fixed;
}

.header {
	top: 0;
	left: 0;
	z-index: 1030;
}

.header .upper {
	display: none;
}

@media (max-width: 1019px) {
	.header .lower {
		background: rgba(0, 0, 0, 0.8);
	}
}

.header .upper .nav .nav-item .nav-link {
	color: #fff;
	font-size: 12px;
}

.header .upper .nav .nav-item .nav-link i {
	float: left;
	margin: 1px 4px 1px 0;
}

.header .lower .navbar-brand img {
	height: 85px;
}

.header .lower .navbar-brand img.red {
	display: none;
}

.header .lower .navbar-brand:hover {
	opacity: 1 !important;
}

.header .lower .navbar-nav .nav-item .nav-link {
	font-family: "NewJuneBold";
	text-transform: uppercase;
	font-size: 13px;
	color: #fff;
}

.header .lower .navbar-nav .nav-item .nav-link:hover,
.header .lower .navbar-nav .nav-item.active .nav-link {
	color: #ff1930;
	opacity: 1;
}

.header-scroll,
.header-page {
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}

.header-scroll .upper,
.header-page .upper {
	background-color: #f0f0f0;
}

.header-scroll .upper .nav .nav-item .nav-link,
.header-page .upper .nav .nav-item .nav-link {
	color: #222;
}

.header-scroll .lower,
.header-page .lower {
	background-color: #fff;
}

.header-scroll .lower .navbar-nav .nav-item .nav-link,
.header-page .lower .navbar-nav .nav-item .nav-link {
	color: #222;
}

.header-scroll .lower .navbar-brand img.red,
.header-page .lower .navbar-brand img.red {
	display: inline-block;
	height: 50px;
}

.header-scroll .lower .navbar-brand img.white,
.header-page .lower .navbar-brand img.white {
	display: none;
}

.header .navbar-toggler {
	color: #fff;
}

.header-scroll .navbar-toggler,
.header-page .navbar-toggler {
	color: #ff1930;
}

@media (min-width: 1020px) {
	.header .upper {
		display: inline-block;
		padding: 7px 0;
  }
}