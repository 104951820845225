html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NewJuneBook";
  font-size: 14px;
}

.wrap {
  min-height: 100%;
}

a,
input,
button,
.transition {
  transition: 0.32s ease;
  -webkit-transition: 0.32s ease;
  -moz-transition: 0.32s ease;
  -o-transition: 0.32s ease;
}

strong {
  font-family: "NewJuneBold";
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
  opacity: 0.5;
}

input,
button,
a {
  outline: none !important;
  box-shadow: none !important;
}

.header,
.main,
.footer,
.section {
  width: 100%;
  height: auto;
  position: relative;
  float: left;
}

.header {
  position: fixed;
}

.header {
  top: 0;
  left: 0;
  z-index: 1030;
}

.mb-xs-1 {
  margin-bottom: 1rem;
}

.cover .mask {
  background-color: rgba(0, 0, 0, 0.6);
}

.header .upper {
  display: none;
}

@media (max-width: 1019px) {
  .header .lower {
    background: rgba(0, 0, 0, 0.8);
  }
}

.header .upper .nav .nav-item .nav-link {
  color: #fff;
  font-size: 12px;
}

.header .upper .nav .nav-item .nav-link i {
  float: left;
  margin: 1px 4px 1px 0;
}

.header .lower .navbar-brand img {
  height: 85px;
}

.header .lower .navbar-brand img.red {
  display: none;
}

.header .lower .navbar-brand:hover {
  opacity: 1 !important;
}

.header .lower .navbar-nav .nav-item .nav-link {
  font-family: "NewJuneBold";
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
}

.header .lower .navbar-nav .nav-item .nav-link:hover,
.header .lower .navbar-nav .nav-item.active .nav-link {
  color: #ff1930;
  opacity: 1;
}

.header-scroll,
.header-page {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}

.header-scroll .upper,
.header-page .upper {
  background-color: #f0f0f0;
}

.header-scroll .upper .nav .nav-item .nav-link,
.header-page .upper .nav .nav-item .nav-link {
  color: #222;
}

.header-scroll .lower,
.header-page .lower {
  background-color: #fff;
}

.header-scroll .lower .navbar-nav .nav-item .nav-link,
.header-page .lower .navbar-nav .nav-item .nav-link {
  color: #222;
}

.header-scroll .lower .navbar-brand img.red,
.header-page .lower .navbar-brand img.red {
  display: inline-block;
  height: 50px;
}

.header-scroll .lower .navbar-brand img.white,
.header-page .lower .navbar-brand img.white {
  display: none;
}

.header .navbar-toggler {
  color: #fff;
}

.header-scroll .navbar-toggler,
.header-page .navbar-toggler {
  color: #ff1930;
}

.cover-home {
  background-image: url("./assets/totem-digital-touch-screen.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.cover-home .caption a {
  font: normal 12px "NewJuneBold";
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  display: inline-block;
  margin: 0 4px;
  border: solid 2px #ff1930;
  padding: 15px 42px;
  border-radius: 50px;
  margin-bottom: 1rem;
  width: 220px;
}

.cover-home .caption a:hover {
  background-color: #ff1930;
  color: #fff;
  opacity: 1;
}

.cover-page {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.cover-page::after {
  display: block;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4) 0,
    #fff 100%
  );
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 10;
}

.cover-page .caption {
  position: relative;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
}

.cover-page .title-section h1 {
  font: normal 52px "NewJuneBold", sans-serif;
  color: #ff1930;
  margin: 0;
}

.cover-page .title-section .breadcrumb {
  padding: 0 !important;
  margin: 15px 0 0 0 !important;
  border-radius: 0;
  background: transparent !important;
}

.cover-page .title-section .breadcrumb .breadcrumb-item {
  font: normal 12px "NewJuneBold", sans-serif;
}

.cover-page .title-section .breadcrumb .breadcrumb-item.active {
  color: #ff1930;
}

.cover-page-alt {
  margin-top: 24px;
}
.cover-page-alt .title-section .breadcrumb {
  padding: 0 !important;
  margin: 15px 0 0 0 !important;
  border-radius: 0;
  background: transparent !important;
}

.cover-page-alt .title-section .breadcrumb .breadcrumb-item {
  font: normal 12px "NewJuneBold", sans-serif;
}

.cover-page-alt .title-section .breadcrumb .breadcrumb-item.active {
  color: #ff1930;
}

.white-bg {
  background-color: #fff;
}

.grey-bg {
  background-color: #f9f9f9;
}

.intro-section {
  overflow: hidden;
  height: 500px !important;
}

.intro-section.alt {
  overflow: hidden;
  height: 120px !important;
}

.intro-section .introCopy {
  min-height: 494px !important;
  z-index: 10;
  position: relative;
  padding: 60px;
}

.intro-section .introCopy .description {
  display: block;
}

.intro-section .introCopy .description .sectionTitle p {
  font: normal 13px/23px "NewJuneBold", sans-serif;
  color: #666666;
  text-transform: uppercase;
  margin: 0;
}

.intro-section .introCopy .description .sectionTitle h2 {
  font: normal 20px/30px "NewJuneBold", sans-serif;
  color: #ff1930;
}

.intro-section .introCopy .description h3 {
  font: normal 16px/26px "NewJuneBold", sans-serif;
}

.intro-section .introCopy .description a {
  display: inline-block;
  padding: 10px 42px;
  background-color: #ff1930;
  color: #fff;
}

.intro-section .glide__arrows {
  height: 494px;
  position: absolute;
  z-index: 11;
  margin-left: -40px !important;
}

.intro-section .glide__arrows button {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  float: left;
  border: none;
  background-color: #ff1930;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

.intro-section .glide__arrows button.disabled {
  opacity: 0.5;
}

.intro-section .glide__track {
  width: 100%;
  height: auto;
  position: relative;
  float: left;
  list-style-type: none;
  z-index: 1;
  margin: 0 !important;
}

.home-img-wrapper {
  max-height: 500px;
  height: 500px !important;
  width: auto;
}

.home-img-wrapper-left {
  max-height: 500px;
  height: 500px !important;
  width: auto;
  position: absolute;
  right: 0;
}

@media (max-width: 575px) {
  .about-company h1 {
    font-size: 40px !important;
  }
  .title-section-text {
    font-size: 32px !important;
  }
  .about-company h1 small {
    font-size: 14px !important;
  }
  .intro-section .glide__track {
    display: none;
  }
  .intro-section .glide__arrows {
    height: 0px;
    position: absolute;
    z-index: 11;
    left: 50%;
    margin-left: -40px !important;
  }
  .intro-section {
    padding-bottom: 70px;
  }
  .intro-section .introCopy {
    padding: 30px 16px;
  }
  .intro-section .introCopy .description a {
    width: 160px;
    position: relative;
    left: 50%;
    margin: 20px 0 20px -80px;
  }
  .about-company {
    padding: 100px 17px !important;
  }
  .product-wrapper.for-kiosk {
    height: 300px !important;
    padding: 0 !important;
  }
}

.intro-section .glide__track .glide__slide {
  width: 25% !important;
  height: 494px;
  padding: 0;
  margin: 0 !important;
  position: relative;
  float: left;
  overflow: hidden;
}

.intro-section .glide__track .glide__slide img {
  height: 100%;
}

.intro-section .glide__track .glide__slide .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.blue-bg {
  background-color: #0d587d;
}

.about-company {
  padding: 100px 0;
}

.about-company h2 {
  margin: 0 0 56px 0;
  font: normal 50px "NewJuneBold";
  color: #fff;
}

.about-company h2 small {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 2px;
  color: #ff1930;
}

.about-company p {
  font: normal 18px/28px "NewJuneBook";
  color: #fff;
}

.image-holder {
  height: 100%;
  position: absolute;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.image-holder-right {
  right: 0;
}

.clients-section {
  padding: 70px;
}

.clients-section h2 {
  font: normal 48px "NewJuneLight";
  color: #182141;
  margin: 0 0 30px 0;
}

.slick-button {
  width: 40px;
  height: 40px;
  background-color: #ff1930 !important;
  display: block;
  color: #fff !important;
  font-size: 20px !important;
  line-height: 40px !important;
  padding: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.clients-section .owl-item {
  position: relative;
  overflow: hidden;
  height: 103px;
}

.clients-section .alt .owl-item {
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 500px;
}

.clients-section .alt .owl-item img {
  display: block;
  width: auto;
  height: 360px;
  margin: 0 auto;
}

.clients-section .owl-item div {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
}

.call-to-action {
  background-image: url("./assets/desenvolvimento-de-projetos-entre-em-contato.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.call-to-action .mask {
  padding: 124px 20px;
  background-color: rgba(7, 52, 74, 0.8);
}

.call-to-action .mask h2 {
  font: normal 36px/46px "NewJuneBold";
  color: #ff1930;
  margin-bottom: 30px;
}

.call-to-action .mask a {
  display: inline-block;
  font: normal 14px "NewJuneBold";
  color: #fff;
  text-transform: uppercase;
  border: solid 2px #ff1930;
  padding: 15px 42px;
  border-radius: 50px;
  width: auto;
  height: 60px;
  padding-top: 20px;
}

.call-to-action .mask a:hover {
  opacity: 1;
  background-color: #ff1930;
}

.down-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: #fff;
  font-size: 52px;
  margin-left: -27px;
}

.down-bounce {
  animation: bounce 2s infinite;
}

@keyframes down-bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.about-page {
  padding: 0 0 100px 0;
}

.about-page h2 {
  font: normal 48px/58px "NewJuneLight", sans-serif;
  color: #182141;
}

.about-page p {
  font-family: "NewJuneBook";
  font-size: 18px;
  line-height: 1.8;
  color: #878c9b;
}

.about-page ol {
  padding-left: 14px;
}

.about-page ol li::before {
  font-weight: bold;
  color: #ff1930;
}

.about-page .nav-solutions {
  margin: 30px 0;
}

.about-page .nav-solutions .nav-item .nav-link .oval {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
  background-color: #fafafa;
  margin-bottom: 15px;
}

.about-page .nav-solutions .nav-item .nav-link .oval img {
  height: 60%;
  margin-top: 50%;
  transform: translateY(-50%);
}

.about-page .nav-solutions .nav-item .nav-link h2 {
  font: normal 14px "NewJuneBook", sans-serif;
  color: #303030;
}

.blog-page,
.cases-page,
.contact-page {
  padding-bottom: 100px;
}

.blog-page .sidebar {
  margin-bottom: 3rem;
}

.blog-page .sidebar h6 {
  font: normal 12px "NewJuneBold";
  text-transform: uppercase;
  margin: 0 0 1rem 0;
  color: #ff1930;
}

.blog-page .sidebar form .form-control {
  height: 52px;
  border-radius: 0;
  border: solid 1px #e6e6e6;
  font-size: 12px;
}

.blog-page .sidebar form.form-search button {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: #5e5e5e;
}

.blog-page .sidebar form.form-search button:hover {
  color: #1a1a1a;
}

.blog-page .sidebar form.form-search .form-control {
  padding-right: 52px;
}

.blog-page .sidebar .nav .nav-item .nav-link {
  background-color: #f0f0f0;
}

.blog-page .sidebar .article-side .cover-image img {
  width: 100%;
  margin-bottom: 1rem;
}

.blog-page .sidebar .article-side h1 {
  font-size: 16px;
  font-weight: bold;
}

.blog-page .sidebar .article-side a.read-more {
  font-size: 12px;
  font-weight: 700;
}

.blog-page .sidebar form.form-newsletter button {
  width: 100%;
  height: 42px;
  border: none;
  background: #303030;
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: #fff;
  margin-top: 1rem;
}

.blog-page .article {
  margin-bottom: 3rem;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
}

.blog-page .article .article-body {
  padding: 30px;
}

.blog-page .article .article-body h1 {
  font-size: 24px;
}

.blog-page .article .article-body h1 {
  font-weight: bold;
}

.blog-page .article .article-body h1 small {
  font-size: 12px;
}

.blog-page .article .read-more {
  font-weight: bold;
}

.pagination .page-item {
  margin: 0 3px 0 3px;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 30px;
  padding: 0;
  text-align: center;
  line-height: 30px;
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  border: none;
  border-radius: 100%;
  color: #5e5e5e;
}

.pagination .page-item .page-link:hover {
  opacity: 1;
  background-color: #1a1a1a;
  color: #fff;
}

.pagination .page-item.disabled .page-link {
  opacity: 0.5;
}

.pagination .page-item.active .page-link {
  background-color: #ff1930;
  color: #fff;
}

.cases-page .nav-solutions {
  margin-bottom: 1.5rem;
}

.cases-page .nav-solutions .nav-item .nav-link {
  font-weight: bold;
  font-size: 12px;
}

.cases-page .nav-solutions .nav-item.active .nav-link {
  background-color: #ff1930;
  color: #fff;
}

.cases-page .cases-wrapper .cases-item {
  background-size: cover;
  margin: 1.5rem 0;
}

.cases-page .cases-wrapper .cases-item:hover {
  opacity: 1;
}

.cases-page .cases-wrapper .cases-item .caption {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(202, 10, 29, 1);
  padding: 30px;
  opacity: 1;
}

.cases-page .cases-wrapper .cases-item .caption h2,
.cases-page .cases-wrapper .cases-item .caption p {
  color: #fff;
  font-weight: bold;
}

.cases-page .cases-wrapper .cases-item .caption hr {
  width: 50%;
  height: 2px;
  background-color: #fff;
  display: block;
  float: left;
  margin: 1rem 50% 1rem 0;
}

.cases-page .cases-wrapper .cases-item:hover .caption {
  opacity: 1;
}

.cases-page span.info {
  background-color: #ff1930;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding: 8px 15px;
  margin-bottom: 2rem;
}

.cases-page .slide .carousel-inner .carousel-item {
  height: 470px;
  background-color: #fafafa;
  position: relative;
  float: left;
}

.cases-page .slide .carousel-inner .carousel-item img {
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cases-page .slide .carousel-inner .controls {
  margin-top: 480px;
  text-align: right;
  position: relative;
  width: auto;
  float: right;
}

.cases-page .slide .carousel-inner .controls .control {
  position: relative;
  width: 40px;
  height: 40px;
  float: left;
  background-color: #ff1930;
  opacity: 1;
  font-size: 12px;
  margin-left: 3px;
}

.cases-page .slide .carousel-inner .controls .control i {
  margin-top: 4px;
}

.contact-page .nav .nav-item .nav-link {
  border-bottom: solid 2px #e6e6e6;
  text-align: center;
  font: normal 14px "NewJuneBold";
  text-transform: uppercase;
}

.contact-page .nav .nav-item.active .nav-link {
  border-color: #ff1930;
  color: #ff1930;
}

.contact-page h2 {
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 3rem 0;
}

.contact-page form input.form-control,
.contact-page form select.form-control {
  height: 52px;
  border-radius: 3px;
  border: none;
  background-color: #f0f0f0;
}

.contact-page form textarea,
.contact-page form textarea:focus {
  height: 100px;
  border-radius: 3px;
  border: none;
  background-color: #f0f0f0;
  outline: none;
  box-shadow: none;
}

.contact-page form label {
  font: normal 14px "NewJuneBold";
  color: #878c9b;
}

.contact-page form label.required::after {
  content: "*";
  color: #ff1930;
}

.contact-page form .form-control {
  font: normal 14px "NewJuneBook";
}

.contact-page form button[type="submit"] {
  padding: 10px 42px;
  border-radius: 3px;
  border: none;
  background-color: #ff1930;
  color: #fff;
  font: normal 14px "NewJuneBold";
}

.contact-page .features-item h6 {
  font: normal 14px "NewJuneBold";
  text-transform: uppercase;
  color: #a52a2a;
  margin-top: 20px;
}

.contact-page .features-item p i {
  color: #a52a2a;
  font-weight: bolder;
}

.contact-page form .group-span-filestyle .btn {
  height: 52px;
  line-height: 52px;
  padding: 0 32px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  color: #fff;
}

.contact-page .alert-success {
  padding: 9px 19px;
  border-radius: 2px;
  border: none;
  background-color: #cff5f2;
  color: #0a7c71;
}

.how-it-works {
  background-color: #0d587d;
  color: #fff;
}

.how-it-works .side-dark {
  padding: 0;
}

.how-it-works .intro {
  padding: 40px 20px 40px 0;
}

.how-it-works .intro .intro-title p {
  font: normal 14px/24px "NewJuneBold";
  margin: 0;
  color: #a2a3b2;
}

.how-it-works .intro .intro-title h2 {
  font: normal 24px/34px "NewJuneBold";
  margin: 0 0 50px 40px;
  color: #fff;
}

.how-it-works .intro .intro-title a {
  background-color: #ff1930;
  display: inline-block;
  color: #fff;
  font: normal 14px "NewJuneBold";
  padding: 8px 16px;
  margin-top: 60px;
}

.how-it-works .intro .intro-title a:hover {
  opacity: 1;
  background-color: #fff;
  color: #ff1930;
}

.how-it-works .row.gutter {
  overflow: hidden;
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.how-it-works .item {
  padding: 35px 60px 25px 80px;
  background-color: rgba(255, 255, 255, 0.1);
}

.how-it-works .row.gutter .item {
  height: 100%;
}

.how-it-works .row.gutter > [class^="col-"],
.how-it-works .row.gutter > [class*="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}

.how-it-works .item i {
  color: #ff1930;
  position: absolute;
  left: 30px;
  top: 35px;
  font-size: 25px;
}

.how-it-works .item h5 {
  font: normal 16px "NewJuneBold";
  text-transform: uppercase;
  margin: 0 0 20px 0;
}

.how-it-works .item .no {
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 30px;
  top: 12px;
  font: normal 60px "NewJuneBold";
}

.cover-home .caption h1 {
  font: normal 40px "NewJuneBold";
  color: #ff1930;
  margin: 1rem 0;
}

.cover-home .caption h2 {
  font: normal 18px "NewJuneBold";
  color: #fff;
  margin: 0;
  letter-spacing: 4px;
}

.cover-home .caption {
  width: 70%;
  position: relative;
  top: 55%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.mobile-row-pd {
  padding: 0 16px;
}

@media (min-width: 425px) {
  .cover-home .caption h1 {
    font: normal 52px "NewJuneBold";
    color: #ff1930;
    margin: 1rem 0;
  }
  .cover-home .caption h2 {
    font: normal 22px "NewJuneBold";
  }
  .cover-home .caption {
    top: 50%;
    transform: translateY(-50%);
  }
  .mobile-its-btn {
    display: none;
  }
  .call-to-action .mask a {
    width: 300px;
  }
}

@media (min-width: 768px) {
  .cover-home .caption h1 {
    margin-bottom: 3rem;
  }
  .how-it-works .first-row {
    margin-bottom: 4px;
  }
}

@media (min-width: 1199px) {
  .img-fluid.alt {
    max-width: 80% !important;
  }
}

@media (max-width: 992px) {
  .img-fluid.alt {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .explanation-section {
    margin-top: 44px;
  }
}

@media (max-width: 768px) {
  .about-company h1 {
    text-align: center;
    font-size: 44px;
  }
  .about-company h1 small {
    font-size: 16px;
  }
  .clients-section h2 {
    text-align: center;
  }
  .call-to-action-btn {
    margin-top: 20px;
  }
  .call-to-action-banner {
    height: auto !important;
  }
  .team-section,
  .team-section-alt {
    text-align: center !important;
    padding: 40px 0;
  }
}

@media (max-width: 991px) {
  .how-it-works .intro {
    padding: 40px 0;
  }
  .how-it-works .intro .intro-title p {
    font: normal 14px/24px "NewJuneBold";
    margin: 0;
    color: #a2a3b2;
  }
  .how-it-works .intro .intro-title h2 {
    margin: 0;
  }
  .how-it-works .intro .intro-title a {
    padding: 19px 32px;
    margin: 0px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .how-it-works .intro .intro-title a:hover {
    opacity: 1;
    background-color: #fff;
    color: #ff1930;
  }
  .how-it-works .first-row {
    margin-bottom: 0px;
  }
  .how-it-works .first-row div {
    margin-bottom: 8px;
  }
  .how-it-works .cl-row div {
    margin-bottom: 40px;
  }
  .how-it-works .cl-row div:first-of-type {
    margin-bottom: 8px;
  }
}

@media (min-width: 992px) {
  .how-it-works .intro {
    padding: 40px 0 40px 40px;
    text-align: right;
  }
  .how-it-works .intro .intro-title h2 {
    margin: 0;
  }
  .how-it-works .intro .intro-title a {
    text-align: center;
    padding: 12px 32px;
  }
}

@media (max-width: 425px) {
  .contact-page .nav .nav-item .nav-link {
    font-size: 13px;
  }
  .how-it-works .intro {
    padding: 40px 0;
  }
  .how-it-works .intro .intro-title p {
    font: normal 14px/24px "NewJuneBold";
    margin: 0;
    color: #a2a3b2;
  }
  .how-it-works .intro .intro-title h2 {
    margin: 0;
  }
  .how-it-works .intro .intro-title a {
    display: none;
  }
  .mobile-its-btn {
    background-color: #ff1930;
    display: inline-block;
    color: #fff;
    font: normal 14px "NewJuneBold";
    padding: 20px 36px;
    margin: 0px 14px 40px 14px;
    width: 100%;
    text-align: center;
  }
  .mobile-its-btn:hover {
    opacity: 1;
    background-color: #fff;
    color: #ff1930;
  }
  .how-it-works .intro .intro-title a:hover {
    opacity: 1;
    background-color: #fff;
    color: #ff1930;
  }
  .how-it-works .item .no {
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 20px;
    top: 50%;
    font: normal 60px "NewJuneBold";
    margin-top: -30px;
  }
  .brand {
    position: relative;
    left: 50%;
    margin-left: -110px;
  }
}

.error-page-margin {
  margin-top: 76px;
}

@media (min-width: 1020px) {
  .error-page-margin {
    margin-top: 128px;
  }
  .header .upper {
    display: inline-block;
    padding: 7px 0;
  }
  .cover-home .caption {
    width: 90%;
  }
  .cover-home .caption h1 {
    font: normal 84px "NewJuneBold";
  }
  .cover-home .caption h2 {
    font: normal 26px "NewJuneBold";
  }
  .cover-home .caption a {
    font: normal 14px "NewJuneBold";
    margin-bottom: 0;
    width: 300px;
    height: 60px;
    padding-top: 20px;
  }
  .cases-page .cases-wrapper .cases-item .caption {
    height: 100%;
    top: 0;
    opacity: 0;
    background: rgba(202, 10, 29, 0.8);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
  .down-btn {
    margin-left: -32px;
  }
}

.social-content {
  padding: 70px 0;
}

.social-content h2 {
  font-size: 32px;
  font-family: NewJuneLight;
  color: #182141;
  line-height: 1.4;
}

.social-content-show {
  padding: 70px 0;
  background: linear-gradient(to right, #7b0018 50%, #fafafa 50%);
  color: #fff;
}

@media (max-width: 575px) {
  .social-content-show {
    background: linear-gradient(to right, #fafafa 50%, #fafafa 50%);
  }
  .social-item h3 {
    color: #7b0018 !important;
  }
  .social-item p {
    color: #222222 !important;
  }
  .social-item h5 {
    color: #777777 !important;
  }
  .social-content-show-alt {
    background: linear-gradient(to right, #f5f5f5 50%, #f5f5f5 50%) !important;
  }
  .social-item-show-alt h4 {
    color: #07344a !important;
  }
  .social-item-show-alt {
    color: #07344a !important;
  }
  .resources-content {
    padding: 70px 32px !important;
  }
}

.social-item {
  padding-right: 5vw;
}

.social-item h3 {
  font-weight: 600;
  color: #f6f6f6;
}

.social-item p {
  font-size: 16px;
  line-height: 1.5;
  color: #f6f6f6;
}

.social-item h5 {
  margin-top: 24px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 16px;
  font-size: 16px;
  line-height: 1.4;
}

.social-item-show {
  color: #878c9b;
  padding: 0 0 0 3.5vw;
}

.social-item-show-alt {
  color: #07344a;
  padding-left: 6vw;
}

.check-list-structure li {
  font-size: 15px;
  margin-top: 8px;
  height: 28px;
  display: inline-block;
}

.social-item-show h4 {
  color: #a52a2a;
  font-weight: 900;
}

.social-content-show-alt {
  padding: 70px 0;
  background: linear-gradient(to left, #0d587d 50%, #f5f5f5 45%);
  color: #878c9b;
}

.check-list-structure {
  padding-left: 0px;
  list-style-type: none;
}

.check-list-structure-alt {
  padding-left: 0px;
  list-style-type: none;
}

.check-list-structure-alt li:before {
  position: relative;
  top: 5px;
  list-style-type: none;
  content: "•";
  line-height: 24px;
  font-size: 28px;
  margin-top: 12px;
}

.social-item-alt {
  margin-right: 3vw;
}

.social-item-alt p {
  font-size: 16px;
  line-height: 1.5;
}

.social-item-alt h4,
h3 {
  color: #a52a2a;
  font-weight: 900;
  margin-bottom: 16px;
}

.blue-svg svg path {
  fill: #07344a;
}

.check-list {
  height: 16px;
  margin: 0 2px 6px 2px;
}

.social-item-show-alt {
  color: #fafafa;
}

.social-item-show-alt h4 {
  color: #fafafa;
  font-weight: 600;
}

.partners-section {
  padding: 70px 0;
  background-color: #fff;
}

.partners-section p {
  margin-top: 32px;
}

.product-box {
  width: 100%;
  padding: 20px 32px;
  display: inline-block;
}

.product-box.alt {
  width: 200px;
  padding: 20px 32px;
  display: inline-block;
}

.product-box-recall {
  width: 180px;
  padding: 40px 20px;
  display: inline-block;
}

.logo-smart.alt {
  width: 140px;
  margin: 0 auto;
}

.logo-smart {
  height: 120px;
  margin: 0 auto;
}

.logo-smart.recall {
  height: 100px;
}

.logo-smart svg {
  margin: 0 auto;
  display: block;
}

.wrapper-with-radius {
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.logo-smart svg path {
  fill: #5d5d5d !important;
  transition: 0.3s;
}

.svg-icon {
  display: inline-block;
}

.default-icon {
  max-width: 140px;
  width: auto;
  height: 100%;
}

.medium-icon {
  max-width: 200px;
  width: auto;
  height: 100%;
}

.medium-icon-wrapper {
  height: 100%;
  width: 100%;
}

.medium-icon-wrapper svg path {
  fill: rgba(10, 10, 10, 0.7);
}

.small-icon {
  max-width: 120px;
  width: 100%;
  height: 120px;
}

.small-icon-wrapper {
  margin: 0 auto;
  width: 120px;
  height: 80px;
}

.check-icon {
  max-width: 18px;
  width: auto;
  height: 100%;
  margin: 0 2px 6px 2px;
}

.background-icon {
  position: absolute;
  height: 360px;
  margin-left: -144.11px;
  left: 50%;
  width: 280px;
}

.background-icon-alt {
  position: absolute;
  height: 420px;
  display: inline-block;
  width: 260px;
}

.background-icon-small {
  position: absolute;
  height: 340px;
  margin-left: -144.11px;
  left: 50%;
  width: 280px;
}

.background-icon-wrapper {
  width: 50%;
  position: relative;
}

.background-icon-wrapper svg path {
  fill: #e1e1e1 !important;
}

.background-icon-wrapper-alt {
  width: 10%;
  position: relative;
}

.background-icon-wrapper-alt svg path {
  fill: #e1e1e1 !important;
}

.product-description {
  display: inline-block;
  width: 100%;
}

.product-description h3 {
  color: #182141;
}

.product-description.recall h3 {
  font-size: 20px !important;
}

.product-wrapper {
  padding: 44px 0;
  transition: 0.3s;
  height: 260px;
}

.product-wrapper:hover {
  background-color: #eaeaea;
}

.product-wrapper:hover .logo-smart svg path {
  fill: #182141 !important;
}

.product-wrapper:hover .logo-smart svg rect {
  fill: #182141 !important;
}

.default-color {
  background-color: #eaeaea !important;
}

.toten-subtitle {
  text-align: center;
  font-size: 36px !important;
  margin: 36px 0;
}

.call-to-action-alt {
  background-image: url("./assets/desenvolvimento-de-projetos-entre-em-contato.jpg");
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  margin: 40px 0;
}

.call-to-action-alt .mask {
  background-color: rgba(7, 52, 74, 0.8);
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 44px 0;
}

.social-content-parallax {
  background-image: url("./assets/equipe-trabalhando-em-desenvolvimento.jpg");
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  margin: 40px 0;
}

.social-content-parallax.alt {
  background-image: url("./assets/parallax_02.jpg");
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  margin: 40px 0;
}

.social-content-parallax.alt-2 {
  background-image: none;
  margin: 12px 0 30px 0;
}

.social-content-parallax h2 {
  font-size: 32px;
  font-family: NewJuneLight;
  color: #fafafa;
  line-height: 1.4;
  font-weight: 600;
}

.social-content-parallax p {
  font-size: 18px;
  font-family: NewJuneLight;
  color: #fafafa;
  line-height: 1.8;
}

.social-content-parallax .mask {
  background-color: rgba(10, 10, 10, 0.85);
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 44px 0;
}

.call-to-action-alt .button {
  font-family: "NewJuneBold";
  font-size: 14px;
}

.call-to-action-alt h3 {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-family: "NewJuneBold";
  line-height: 40px;
}

.outline-button {
  border: 2px solid #ffffff;
  border-radius: 56px;
  padding: 8px 20px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.04em;
  position: relative;
  top: 9px;
}

.outline-button:hover {
  color: #ffffff;
}

.about-content h1 {
  font-family: NewJuneLight;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.2;
  color: #182141;
  margin-bottom: 30px;
  position: relative;
}

.about-content p,
.social-content p {
  font-family: "NewJuneBook";
  font-size: 18px;
  line-height: 1.8;
  color: #878c9b;
}

.about-content h2.title-alt {
  font-size: 16px;
  text-transform: uppercase;
  font-family: NewJuneBold;
  font-weight: 600;
  color: #a52a2a;
  margin: 32px 0 20px 0;
}

ul.feat li {
  font-family: "NewJuneBook";
  font-size: 18px;
  line-height: 1.8;
  color: #878c9b;
  height: 28px;
  display: block !important;
}

ul.feat li span {
  position: relative;
  top: 2px;
}

.quarter-height {
  height: 360px !important;
}

.no-height {
  height: 0px !important;
}

.separator {
  margin: 32px 0;
  border: 0;
}

@keyframes separator-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes separator-width-alt {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.separator-line {
  width: 0;
  border: 0;
  border-bottom: 2px solid #dadada;
  animation: separator-width 1s ease-out forwards;
}

.toten-subtitle {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

.explanation-section {
  padding: 70px 0;
  background-color: #f5f5f5;
}

.explanation-section .explanation-title {
  color: #182141;
  font-size: 32px;
  font-family: NewJuneLight;
  line-height: 1.4;
}

.explanation-section .explanation-title div {
  margin-bottom: 27px;
  font-weight: 600;
}

.explanation-section .explanation-title span {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  margin-bottom: 18px;
}

.explanation-section span {
  font-family: "NewJuneBook";
  font-size: 20px;
  font-weight: 600;
  line-height: 1.8;
  color: #878c9b;
}

.explanation-section p {
  font-family: "NewJuneBook";
  font-size: 20px;
  line-height: 1.8;
  color: #878c9b;
}

.explanation-section .explanation-counter {
  color: #182141;
  font-weight: 900;
  font-size: 18px;
}

.explanation-section .explanation-counter span {
  font-weight: 400;
}

.dark-bg {
  background-color: #07344a;
}

.resources-content {
  padding: 70px 0;
}

.resources-content h2 {
  color: #f2b636;
  font-size: 28px;
  font-family: "NewJuneLight";
  line-height: 1.4;
  margin-bottom: 30px;
  font-weight: 600;
}

.resources-content h3 {
  color: #fff;
  font-size: 20px;
  font-family: "NewJuneLight";
  line-height: 1.6;
  margin-bottom: 8px;
}

.resources-content .r-item {
  position: relative;
  float: left;
  margin-bottom: 30px;
}

.resources-content .r-item .number {
  position: absolute;
  top: 0;
  left: 0;
  color: #f2b636;
  font-size: 30px;
  min-width: 36px;
  line-height: 1;
  font-family: "NewJuneLight";
}

.resources-content .r-item .icon {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 36px;
}

.resources-content .r-item .icon img {
  width: 36px;
  height: auto;
  position: relative;
  float: left;
}

.resources-content .r-item .content {
  width: 100%;
  height: auto;
  position: relative;
  float: left;
  padding-left: 46px;
}

.resources-content .r-item .content p {
  font-size: 16px;
  line-height: 1.625;
  font-family: "NewJuneBook";
  color: rgba(255, 255, 255, 0.8);
}

.table thead tr th {
  font-family: "NewJuneBold";
  font-size: 14px;
  text-transform: uppercase;
}

.table tbody tr td {
  font-family: "NewJuneBook";
  font-size: 14px;
}

.table small {
  font-size: 12px;
  color: #a52a2a;
  font-family: "NewJuneBold";
}

.collapse-body {
  height: 240px;
  background-color: #eaeaea;
  border-top: 1px solid #dadada;
}

.collapse-body h4 {
  color: #a52a2a;
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
}

.error-container h1 {
  font-weight: 600;
  color: #a52a2a;
  font-size: 200px;
}

.error-parallax {
  background-image: url("./assets/error404.jpg");
  background-size: cover;
  margin: 40px 0;
  height: 130vh !important;
}

.error-parallax h2 {
  font-size: 32px;
  font-family: NewJuneLight;
  color: #fafafa;
  line-height: 1.4;
  font-weight: 600;
}

.error-parallax h3 {
  font-size: 72px;
  font-family: NewJuneLight;
  color: ubl #ffffff;
  line-height: 1;
  font-weight: 600;
}

.error-parallax p {
  font-size: 18px;
  font-family: NewJuneLight;
  color: #fafafa;
  line-height: 1.8;
}

.error-parallax a {
  display: inline-block;
  font: normal 14px;
  font-family: "NewJuneBook";
  color: #fff;
  text-transform: uppercase;
  border: solid 2px #ff1930;
  padding: 15px 42px;
  border-radius: 50px;
  width: 300px;
  height: 60px;
  padding-top: 20px;
  margin-top: 20px;
}

.error-parallax a:hover {
  background-color: #ff1930;
  opacity: 1;
  text-decoration: none;
}

@media (max-width: 575px) {
  .error-container h1 {
    font-size: 100px;
  }
  .error-container h3 {
    font-size: 60px;
    margin-top: 20px;
  }
  .error-container img {
    height: 120px;
    margin: 0 auto;
  }
  .error-parallax a {
    width: 222px;
    margin: 20px 0;
  }

  .product-box-recall {
    width: auto !important;
    padding: 0 !important;
    display: block !important;
  }

  .product-wrapper.recall {
    height: 320px !important;
  }
}

.social-card {
  width: 100%;
  height: auto;
  position: relative;
  float: left;
  background-color: #fff;
  color: #0a0a0a;
  padding: 30px;
  margin-top: 30px;
}

.social-card.alt {
  width: 100%;
  height: auto;
  position: relative;
  float: left;
  background-color: #fff;
  color: #0a0a0a;
  padding: 30px;
  margin-top: 30px;
}

.social-card.alt-2 {
  width: 100%;
  height: 172px;
  position: relative;
  float: left;
  background-color: #fff;
  color: #0a0a0a;
  padding: 30px;
  margin-top: 30px;
}

.social-card.alt-2 p {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 20px;
  font-size: 16px;
  color: #878c9bee;
}

.social-card span {
  font-size: 42px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.social-title h2 {
  font-size: 42px;
  font-weight: 300;
  margin-top: 0;
  color: #fff;
}

.social-card p {
  font-size: 16px;
  font-weight: 300;
  padding-left: 55px;
  margin: 0;
  color: #1a1a1a;
}

.social-responsible h2 {
  font-size: 32px;
  font-family: "NewJuneLight";
  color: #182141;
  line-height: 1.4;
}

.social-responsible p {
  font-size: 18px;
  font-family: "NewJuneBook";
  color: #878c9b;
  line-height: 1.8;
}

.apps-page p {
  font-family: "NewJuneBook";
  font-size: 19px;
  line-height: 1.8;
  color: #7e7e7e;
}

.apps-page p span {
  font-weight: 600;
}

.apps-page h2 {
  font-size: 22px;
}

.apps-page .subtitle {
  color: #2e2e2e;
  line-height: 2rem;
  font-size: 26px;
  font-weight: 400;
  margin-left: 20px;
}

.app-list-structure {
  padding-left: 0px;
  list-style-type: none;
}

.app-list-structure li {
  font-family: "NewJuneBook";
  font-size: 20px;
  color: #797878;
  padding: 4px 20px;
}

.app-list-structure li:before {
  position: relative;
  top: 3px;
  list-style-type: none;
  content: "•";
  line-height: 40px;
  font-size: 28px;
}

.mock-wrapper {
  width: 100%;
  height: 420px;
  margin: auto 0;
}

.mock-wrapper img {
  width: auto;
  height: 100%;
}

.os-logo-wrapper {
  padding: 12.5px 0;
}

.os-logo-wrapper svg:not(:last-child) {
  margin-right: 20px;
}

.os-logo-wrapper svg path {
  fill: #aaaaaa;
}

.os-logo-wrapper svg:hover path {
  fill: #cacaca;
}

.apps-page-alt {
  background-color: #fafafa;
  padding: 40px 0;
}

.proccess-card {
  height: 400px;
  width: 220px;
  padding: 16px 24px;
  display: inline-block;
  border-radius: 6px;
  margin: 20px 0;
  box-shadow: 1px 1px 6px rgba(2, 22, 32, 0.158);
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.proccess-card:hover {
  transform: scale(1.15);
  box-shadow: 2px 2px 6px rgba(2, 22, 32, 0.425);
}

.proccess-card .header-title {
  font-size: 19px;
  font-weight: 600;
  color: #444444;
  text-align: center;
}

.proccess-card .header-icon {
  width: 80px;
  margin: 0 auto 12px auto;
}

.proccess-card .header-icon img {
  height: 80px;
}

.proccess-card .description {
  font-size: 14px;
  color: #5e5d5d;
  text-align: center;
}

.team-section p {
  margin-top: 44px;
  font-size: 17px;
}

.team-section {
  text-align: right;
  padding-top: 60px;
  font-size: 22px;
  color: #5e5d5d;
  line-height: 2rem;
}

.team-section-alt {
  text-align: right;
  padding-top: 120px;
  font-size: 22px;
  color: #5e5d5d;
  line-height: 2rem;
}

.team-section-alt p {
  margin-top: 44px;
  font-size: 17px;
}

.half-light-grey {
  background-image: linear-gradient(to right, #ffffff 50%, #fcfcfc 50%);
}

.call-to-action-banner {
  background-color: #a52a2a;
  height: 200px;
  padding: 28px 0;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}

.call-to-action-btn {
  padding: 8px 24px;
  border: 1px solid #ffffff;
  border-radius: 20px;
}

.call-to-action-banner a {
  font-size: 20px;
}

.call-to-action-banner .separator {
  border-right: 2px solid #ffffff;
  margin: 0;
}

.call-to-action-banner .separator svg path {
  fill: #ffffff;
}

.call-to-action-banner .separator svg {
  float: right;
  margin-right: 24px;
}

.consulting-page {
  font-size: 24px;
  color: #212529;
}

.consulting-page p {
  color: #212529bb;
  font-size: 20px;
}

.consulting-page p span {
  font-weight: 600;
}

.consulting-page .consulting-img-wrapper {
  margin: 32px 0;
}

.consulting-page img {
  height: 180px;
}

.consulting-page img.left {
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 50%;
  transform: translateY(-50%);
}

.consulting-page img.right {
  position: absolute;
  right: 50%;
  margin-right: -300px;
  top: 50%;
  transform: translateY(-50%);
}

.main-timeline {
  position: relative;
  transition: all 0.4s ease 0s;
}

.main-timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #fafafa;
  position: absolute;
  top: 0;
  left: 50%;
}

.main-timeline .timeline {
  position: relative;
  margin-top: 60px;
}

.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.main-timeline .timeline-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #a52a2a;
  border: 2px solid #a52a2a;
  position: absolute;
  top: 0;
  left: 3px;
  right: 0;
  margin: 0 auto 20px auto;
  overflow: hidden;
}

.main-timeline .timeline-content {
  width: 45%;
  padding: 40px 20px;
  border-radius: 5px;
  text-align: right;
  transition: all 0.3s ease 0s;
}

.main-timeline .title {
  font-size: 24px;
  font-weight: 500;
  color: #5a5a5a;
}

.main-timeline .description {
  font-size: 16px;
  color: #8a8a8a;
  line-height: 2;
}

.main-timeline .timeline-content.right {
  float: right;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .main-timeline:before {
    left: 0;
  }
  .main-timeline .timeline-icon {
    left: -8px;
    margin: 0;
  }
  .main-timeline .timeline-content {
    width: 90%;
    float: right;
  }
  .consulting-page .consulting-img-wrapper {
    display: none;
  }
  .team-section-alt {
    padding: 40px 0;
  }
  .main-timeline {
    left: 12px;
  }
  .main-timeline .timeline {
    margin-top: 0px;
  }
  .main-timeline .timeline-content {
    text-align: left;
  }
}

.gallery-content {
  background-color: #fafafa;
  padding: 24px 0;
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.gallery-col {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.gallery-col-alt {
  flex: 33%;
  max-width: 33%;
}

.gallery-col img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.gallery-col img:hover {
  transform: scale(1.15);
  transition: 0.3s;
}

@media screen and (max-width: 800px) {
  .gallery-col {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .gallery-col {
    flex: 100%;
    max-width: 100%;
  }
}

.toten-explanation p {
  margin-bottom: 0.5rem;
}

.toten-explanation p span {
  font-weight: 600;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .product-box.alt {
    width: auto !important;
  }
  .default-icon {
    width: auto !important;
    max-width: 80px !important;
    height: 100% !important;
    max-height: 80px !important;
  }
  .logo-smart.alt {
    width: 80px;
  }
  .logo-smart {
    height: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .product-box.alt {
    width: 240px;
  }
  .product-description {
    text-align: center;
  }
  .product-wrapper {
    height: 360px !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .product-box.alt {
    width: 240px;
  }
  .product-description {
    text-align: center;
  }
  .product-wrapper {
    height: 360px !important;
  }
}

@media screen and (max-width: 575px) {
  .product-wrapper {
    margin: 0 auto;
    width: 300px;
  }
  .product-box.alt {
    width: 270px;
    padding: 0 0 20px 0;
  }
  .default-icon {
    max-height: 80px;
  }
  .logo-smart {
    height: 80px;
  }
  .product-description {
    text-align: center;
  }
  .call-to-action-alt h3 {
    text-align: center;
  }
}

.link-whats {
  width: 72px;
  height: 72px;
  position: fixed;
  z-index: 999999999;
  right: 32px;
  bottom: 32px;
  background-image: url("./assets/whats-app.png");
  background-position: center !important;
  background-size: cover !important;
  cursor: pointer;
}

@media (max-width: 576px) {
  .link-whats {
    width: 52px;
    height: 52px;
  }
}

.sm-full-height {
  height: 100vh !important;
}

.about-image {
  background-image: url("./assets/about.png");
}

.about-us-image {
  background-image: url("./assets/predio-comercial-its-tecnologia.jpg");
  padding-top: 124px;
}

.totens-image {
  background-image: url("./assets/totem-digital-autoatendimento.jpg");
  padding-top: 124px;
}

.apps-image {
  background-image: url("./assets/computador-com-linhas-de-código-de-programacao.jpg");
  padding-top: 124px;
}

.consulting-image {
  background-image: url("./assets/reuniao-com-consultores.jpg");
  padding-top: 124px;
}

.cases-image {
  background-image: url("./assets/cases-diversos.jpg");
  padding-top: 124px;
}

.totens-tablet-image {
  background-image: url("./assets/equipamentos-de-totens-tablet-em-evento.jpg");
  padding-top: 124px;
}

.totens-smart-image {
  background-image: url("./assets/equipamentos-totens-tablet-smart.jpg");
  padding-top: 124px;
}

.totens-smart-pay-image {
  background-image: url("./assets/pin-pad-acoplado-em-totem-de-pagamento.png");
  padding-top: 124px;
}

.totens-table-image {
  background-image: url("./assets/mesa-touch-screen-01.jpg");
  padding-top: 124px;
}

.totens-autodesk-image {
  background-image: url("./assets/totens-de-autoatendimento.jpg");
  padding-top: 124px;
}

.totens-charger-image {
  background-image: url("./assets/totem-carregador-de-celular.jpg");
  padding-top: 124px;
}

.contact-image {
  background-image: url("./assets/informacoes-e-canais-de-contato-da-its.jpg");
  padding-top: 124px;
}

.work-image {
  background-image: url("./assets/predio-comercial-its-tecnologia.jpg");
  padding-top: 124px;
}

.toten-subtitle-color {
  color: #212529;
}

.custom-margin-1 {
  margin: 40px 0;
}

.no-box-addition {
  margin: 0;
  padding: 0;
}

.v-padding-70 {
  padding: 70px 0;
}

.p-left-36 {
  padding-left: 36px;
}

.p-bottom-40 {
  padding-bottom: 60px;
}

.p-bottom-60 {
  padding-bottom: 60px;
}

.p-bottom-70 {
  padding-bottom: 70px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-40 {
  margin-top: 40px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-bg-img {
  background-image: none;
}

.dark-blue-color {
  color: #07344a !important;
}

.dark-grey-color {
  color: #313439;
}

.dark-red-color {
  color: #a52a2a !important;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.h-110 {
  height: 110px;
}

.h-240 {
  height: 240px;
}

.h-500 {
  height: 500px;
}

.m-width-480 {
  max-width: 480px;
}

.very-light-bg {
  background-color: #fafafa;
}

.m-text {
  font-size: 15px;
}

.file-input {
  height: 52px;
  background-color: #f0f0f0;
  border-radius: 6px;
  width: 100%;
}

.new-button {
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #f0f0f0;
  font-size: 16px;
  color: #fff;
  margin: 10px 0 24px 0;
  height: 52px;
  padding: 18px;
}

.new-button:hover {
  background-color: #ececec;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: #333 !important;
}

.button-wrap {
  position: relative;
}

.p-h-60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

/* FOOTER CSS */

.footer {
  background-color: #0e1220 !important;
  color: #878c9b !important;
}

.footer .upper {
  padding: 45px 0 45px 0;
}

.footer .upper .nav .nav-item .nav-link {
  padding-left: 0;
}

.footer .upper .nav .nav-item:first-child .nav-link {
  padding-top: 0;
}

.footer .upper .nav .nav-item:last-child .nav-link {
  padding-bottom: 0;
}

.footer .upper h6 {
  font-family: "NewJuneBold";
  text-transform: uppercase;
  font-size: 14px;
  color: #ff1930;
  margin: 0 0 1rem 0;
}

.footer .lower {
  padding: 0 0 45px 0;
}

.footer .upper .brand img {
  width: 60%;
}

.footer .upper .brand:hover {
  opacity: 1;
}

.footer .upper .nav-partners .nav-item .nav-link:hover {
  opacity: 1;
}

.footer .upper .nav-partners .nav-item .nav-link img {
  height: 55px;
}

.footer .upper .mt-4 {
  text-align: center;
}

.footer .upper .causes-container {
  display: inline-block;
}

.footer-cause-wrapper {
  text-align: center;
}

.brand {
  position: relative;
  left: 50%;
  margin-left: -110px;
}

.footer-cause {
  display: inline-block;
  margin-bottom: 20px;
}

.footer-cause:first-child {
  margin-right: 20px;
}

.footer-link {
  color: #878c9b;
  cursor: pointer !important;
}

.footer-link:hover {
  color: #838c9b;
  cursor: pointer !important;
}

/* END FOOTER CSS */

.totens-tablet-description {
  font-family: "NewJuneBook";
  font-size: 18px;
  line-height: 1.8;
  color: #878c9b;
}

.products-recall-title {
  font-size: 28px;
  color: #07344a;
  margin-top: 20px;
  font-weight: 600;
}

.products-recall-description {
  font-size: 13px;
}

.case-section {
  margin-top: 127px;
}

.app-showcase {
  padding: 30px 0 40px 0;
  background-image: linear-gradient(#ffffff, #f0f2f4);
}

.app-showcase .app-logo {
  max-height: 60px;
  width: auto;
  margin-bottom: 40px;
}

.app-showcase h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
}

.app-showcase p {
  margin-bottom: 0;
  font-size: 16px;
}

.context-image {
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
  top: 208px;
  z-index: 3;
  max-width: 46vw;
}
.context-kiosk {
  max-height: 100%;
  max-width: 47vw;
  right: 0;
  bottom: 0;
  position: absolute;
}

.app-objective {
  padding-top: 40px;
}

.app-objective h3 {
  color: #333;
  margin-bottom: 28px;
}

.app-objective p {
  font-size: 16px;
  margin-bottom: 28px;
}

.app-call-to-action-title {
  margin-top: 44px;
  font-size: 28px;
}

.app-call-to-action-subtitle {
  font-size: 22px !important;
}

.app-call-to-action-card-text {
  font-size: 14px !important;
}

.app-call-to-action-text {
  font-size: 18px !important;
}

.cases-subtitle {
  font-family: "NewJuneBook";
  font-size: 19px;
  line-height: 1.8;
  color: #7e7e7e;
  text-align: center;
}
